import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Happy New Year!`}</strong></p>
    <p><strong parentName="p">{`*`}{`We will have our free New Years Day class today at 10:30am so invite
a friend!  There will be no other classes or open gym today.`}</strong></p>
    <p>{`Partner WOD`}</p>
    <p>{`2 rounds for time of:`}</p>
    <p>{`20 Calorie Row`}</p>
    <p>{`19 Burpees`}</p>
    <p>{`20 Calorie Bike`}</p>
    <p>{`19 V Ups`}</p>
    <p>{`20 Double Unders`}</p>
    <p>{`19 Ball Slams (30/20)`}</p>
    <p>{`20 Wall Balls (20/14)`}</p>
    <p>{`19 Ring Rows`}</p>
    <p>{`20 GHD Situps`}</p>
    <p>{`19 Jump Squats`}</p>
    <p><em parentName="p">{`*`}{`Partners must alternate 20/19 sets throughout the wod.  So partner 1
does 20 cal row, 19 burpees and partner 2 does 20 cal bike, 19 v ups,
etc.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, January 5th, all members are invited to our get together
at 5:00pm at Foam Warriorz and then dinner afterward.  Please RSVP if
you can make it.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s and Sunday’s each weekend after
our CrossFit classes.  Email Daniel for more information.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  You still
have time to register but we only have 3 spots left.  Get your teams
together and registered soon to reserve your spot! `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      